var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('vcad-dialog',{attrs:{"title":"Templates","dialogWidth":"900"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('vcad-template',{attrs:{"vcadData":_vm.selectedVcadItem,"vcadViewList":_vm.selectedViewList}})]},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"d-inline-flex justify-end",staticStyle:{"width":"100%"}},[_c('v-btn',{staticClass:"vcad-btn",attrs:{"outlined":"","small":"","color":""},on:{"click":function($event){$event.stopPropagation();_vm.openDialog = false}}},[_vm._v("CLOSE")])],1)]},proxy:true}]),model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}}),(_vm.headers)?_c('v-data-table',{staticClass:"elevation-1 vcad-table mx-16",attrs:{"items":_vm.userFederations,"headers":_vm.headers,"item-key":"multimodelProjectGuid","loading":_vm.fedsLoading,"show-expand":"","expanded":_vm.expanded,"search":_vm.search,"custom-filter":_vm.customFilter},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"elevation-0 my-5",attrs:{"headers":_vm.expansionTableHeaders,"items":item.slots,"dense":""},scopedSlots:_vm._u([{key:"item.FileName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center flex-row"},[_c('div',{staticClass:"d-flex"},[_c('v-img',{staticClass:"mr-2",attrs:{"max-height":"20","max-width":"20","contain":"","src":_vm.getFileIconExt(item.fileExt)}}),_c('div',[_vm._v(" "+_vm._s(item.FileName)+" ")])],1)])]}},{key:"item.expanded-actions",fn:function(ref){
var slotItem = ref.item;
return [_c('v-btn',{staticClass:"vcad-btn file-action-btn",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openTemplateSelector(slotItem)}}},[_vm._v(" Templates ")])]}}],null,true)})],1)]}},{key:"top",fn:function(){return [_c('div',{staticClass:"primary lighten-2 vcad-table-top"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":9}},[_c('h3',{staticClass:"white--text"},[_vm._v("Federation Management")])]),_c('v-col',{staticClass:"flex-row d-flex",attrs:{"cols":3}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search by federation or file SLOT ID","hide-details":"","clearable":"","solo":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('vcad-multi-model-dialog',{attrs:{"userFederations":_vm.userFederations,"folderFiles":_vm.tmpFileList,"folderId":_vm.folderId},on:{"dialogClosed":_vm.dialogClosed},scopedSlots:_vm._u([{key:"fedDialogActivator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"vcad-btn",attrs:{"small":"","outlined":"","elevation":"2","max-width":"12em","color":"primary"},on:{"click":function($event){return _vm.setupDialog(item)}}},on),[_c('v-icon',[_vm._v("mdi-hexagon-multiple")]),_vm._v(" Manage ")],1)]}}],null,true)})]}}],null,true)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }